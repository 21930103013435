<template>
  <div class="cooperation">
    <img src="../../assets/mobile/cooperation/banner.svg" alt style="width:102%;transform: translateX(-1%);" />
    <div class="bg1">
      <div class="imgs">
        <div class="img1">
          <img src="@/assets/mobile/cooperation/one.jpg" alt style="width:100%" ref="img1" />
          <!-- <div class="box1">
            <div class="title">市场空间大</div>
            <div class="text">蓝海市场，数百万家零</div>
            <div class="text">售店遍布大街小巷皆为</div>
            <div class="text">目标客户，各地市场亟</div>
            <div class="text">待开发</div>
            
          </div> -->
        </div>
        <div class="img2">
          <img src="@/assets/mobile/cooperation/two.jpg" alt style="width:100%;" ref="img2" />
          <!-- <div class="box2">
            <div class="title">收益多元化</div>
            <div class="text">模式创新科技加持，业</div>
            <div class="text">务含金量高，坐享流水</div>
            <div class="text">分佣、广告分成等多项</div>
           <div class="text">持续性收益</div>
          </div> -->
        </div>
        <div class="img3">
          <img src="@/assets/mobile/cooperation/three.jpg" alt style="width:100%;" ref="img3" />
          <!-- <div class="box3">
            <div class="title">回本周期快</div>
            <div class="text">万元投入成为区域独家</div>
            <div class="text">渠道合作伙伴，业务盈</div>
            <div class="text">利模型清晰，全程一对</div>
            <div class="text">一扶持</div>
          </div> -->
        </div>
        <div class="img4">
          <img src="@/assets/mobile/cooperation/four.jpg" alt style="width:100%;" ref="img4" />
          <!-- <div class="box4">
            <div class="title">国家政策支持</div>
            <div class="text">多项政策支持智能化技</div>
            <div class="text">术应用，鼓励改造现有</div>
            <div class="texts">门店，开设24小时营</div>
            <div class="text">业便利店</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="ear">
        <div class="earTitle">
        快进商店渠道合作伙伴
        <div>拥有多项业务收益</div>
      </div>
      <img src="../../assets/mobile/cooperation/bg.svg" alt="" style="width: 100%;height: 100%;">
       <div class="btn" @click.stop="openDialog">立即申请</div>
    </div>
    <!-- <div class="earnings">
    
      <div class="btn" @click.stop="openDialog">立即申请</div>
    </div> -->
    <div class="bg2title">
      我们为渠道合作伙伴
      <div>切实提供全方位支持</div>
    </div>
    <div class="imgList">
      <div style="width:30px;height:10px"></div>
      <div
        v-for="(item,index) in list"
        :key="item.url"
        style="font-size:0;"
        class="gifItem"
        :class="{'lastGif':index==5}"
      >
        <div class="item" style="display：block">
          <img :src="item.icon" alt />
          <div class="title">{{item.title}}</div>
          <div class="line"></div>
          <div class="text">{{item.text}}</div>
        </div>
        <img :src="item.url" alt style="display：block;width:14.8125rem;height:14.8125rem" />
      </div>
    </div>
    <div class="bg2">
      <div style="width:100%;height:1px"></div>
      <div class="bgtitle">
        披荆斩棘，一同前行
        <div>我们希望您：</div>
      </div>
      <div class="bgList">
        <div v-for="(item,index) in list1" :key="item.name" class="listItem">
          <div>
            <img :src="item.url" alt />
          </div>
          <div class="names" :class="{'last':index==3}">{{item.name}}</div>
        </div>
         <div class="line1"></div>
      <div class="line2"></div>
      </div>
     
    </div>

    <img src="../../assets/mobile/cooperation/bg3.png" alt style="width:100%;margin-bottom:-10px" />

    <mGobalFooter></mGobalFooter>
  </div>
</template>
<script>
import mGobalFooter from "../../components/mobile/mGobalFooter";
export default {
  components: {
    mGobalFooter
  },
  data() {
    return {
      list: [
        {
          url: require("../../assets/mobile/cooperation/kj1.png"),

          icon: require("../../assets/mobile/cooperation/kjicon1.svg"),
          title: "项目培训支持",
          text: "提供一站式基础性培训，帮助合伙人团队快速熟悉业务"
        },
        {
          url: require("../../assets/mobile/cooperation/kj2.png"),

          icon: require("../../assets/mobile/cooperation/kjicon2.svg"),
          title: "市场开拓支持",
          text: "从拓展话术到销售技能，为团队提供完善的业务技能培训"
        },
        {
          url: require("../../assets/mobile/cooperation/kj3.png"),
          icon: require("../../assets/mobile/cooperation/kjicon3.svg"),
          title: "业务运营支持",
          text: "丰富的运营活动助力业务开展,定期分析数据，量身定制方案"
        },
        {
          url: require("../../assets/mobile/cooperation/kj4.png"),
          icon: require("../../assets/mobile/cooperation/kjicon4.svg"),
          title: "软硬件维保支持",
          text: "自有的强大研发团队保障软硬件更新及维护，让您售后无忧"
        },
        {
          url: require("../../assets/mobile/cooperation/kj5.png"),

          icon: require("../../assets/mobile/cooperation/kjicon5.svg"),
          title: "客户服务支持",
          text: "总部客服团队全年365天无休待命，全力保障消费者体验"
        },
        {
          url: require("../../assets/mobile/cooperation/kj6.png"),
          icon: require("../../assets/mobile/cooperation/kjicon6.svg"),

          title: "品牌合作支持",
          text: "平台背书高效对接广告主等合作方，无差别引入合伙人区域"
        }
      ],
      list1: [
        {
          name:
            "看好智能零售行业的市场发展前景，坚信科技的力量可以给人们带来美好的生活",
          url: require("../../assets/mobile/cooperation/icon1.svg")
        },
        {
          name:
            "了解并认同快进商店企业文化，愿长期合作，持续经营，有责任感、事业心",
          url: require("../../assets/mobile/cooperation/icon2.svg")
        },
        {
          name:
            "对快进商店业务模式充分认可，可以理性判断，认真选择，且具有较强执行力",
          url: require("../../assets/mobile/cooperation/icon3.svg")
        },
        {
          name:
            "具备一定资金实力和管理经验，熟悉当地市场，固定办公场所和成熟业务团队更佳",
          url: require("../../assets/mobile/cooperation/icon4.svg")
        }
      ]
    };
  },
  methods: {
    openDialog() {
      this.$router.push("/mDialogConsult");
    }
  }
};
</script>
<style lang="scss" scoped>
.cooperation {
  margin-top: 55px;
  font-family: opposr, sans-serif;
  overflow-x: hidden;
}
.bg1 {
  margin-top: 2.1875rem;
  width: 100%;
  position: relative;

  .imgs {
    text-align: center;
    // animation: change 1s;
    // animation-fill-mode: forwards;
    // -webkit-animation-fill-mode: forwards;
  }
  .img1,
  .img3 {
    margin: 0 0 0 0;
    position: relative;
    width: 100%;
  }
  .img2,
  .img4 {
    position: relative;
    margin: 0 0 0 0;
    width: 100%;
  }
  .box1 {
    position: absolute;
    top: 51%;
    letter-spacing: 2px;
    left: 50%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1rem;

      font-weight: 500;
      color: #3b3b72;

      text-align: left;
      font-family: opposm, sans-serif;
      margin-bottom: 0.375rem;
    }
    .text {
      text-align: left;
      font-size: .82rem;

      font-weight: 400;
      color: #808080;
    }
  }
  .box2 {
    position: absolute;
    top: 49%;
    letter-spacing: 2px;
    right: 49%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1rem;

      font-family: opposm, sans-serif;
      font-weight: 500;
      color: #3b3b72;

      text-align: right;
      margin-bottom: 0.375rem;
    }
    .text {
      
      text-align: right;
      font-size: .82rem;

      font-weight: 400;
      color: #808080;
      
    }
    .texts{font-size: .75rem;

      font-weight: 400;
      color: #808080;}
  }
  .box3 {
    position: absolute;
    top: 51%;
    letter-spacing: 2px;
    left: 50%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1rem;

      font-family: opposm, sans-serif;
      font-weight: 500;
      color: #3b3b72;

      text-align: left;
      margin-bottom: 0.375rem;
    }
    .text {
      text-align: left;
       font-size: .82rem;

      font-weight: 400;
      color: #808080;
    }
  }
  .box4 {
    position: absolute;
    top: 47.5%;
    letter-spacing: 2px;
    right: 49%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1rem;

      font-family: opposm, sans-serif;
      font-weight: 500;
      color: #3b3b72;

      text-align: right;
      margin-bottom: 0.375rem;
    }
    .text {
      text-align: right;
      font-size: .82rem;

      font-weight: 400;
      color: #808080;
    }
    .texts{
       text-align: right;
      font-size: .82rem;
      letter-spacing: 2.5px;
      font-weight: 400;
      color: #808080;
    }
  }
}
.ear{
  position: relative;
  .earTitle {
    width: 100%;
    text-align: center;
    position: absolute;
    top:1%;
    left: 50%;
    transform: translate(-50%, 0);
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 2rem;
    font-family: opposm, sans-serif;
  }
  .btn {
    width: 7.5rem;
    height: 2.25rem;
    line-height: 2.25rem;
    background: #ffffff;
    font-size: 0.9375rem;
    text-align: center;
    font-weight: 400;
    color: #1f10e0;
    border-radius: 3px;
    position: absolute;
    bottom: 2rem;
    background: #fff;
    left: 50%;
font-family: opposm, sans-serif;
    transform: translate(-50%, 0);

    cursor: pointer;
    letter-spacing: 2px;
  }
}
.earnings {
  background: url("../../assets/mobile/cooperation/bg.svg") no-repeat;
  width: 100%;
  height: 23.75rem;
  position: relative;
  background-size: 100% 100%;

  font-size: 0.75rem;
  font-family: opposm, sans-serif;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  margin-top: 1.9375rem;
  img{
    position: absolute;
    top: 0;
    left: 0;
    
  }
  .earTitle {
    letter-spacing: 1px;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 2rem;
  }
  .text1 {
    position: absolute;
    top: 42%;
    left: 25.5%;
  }
  .text2 {
    position: absolute;
    top: 42%;
    left: 56.5%;
  }
  .text3 {
    position: absolute;
    top: 65.3%;
    left: 10.5%;
  }
  .text4 {
    position: absolute;
    top: 65.5%;
    left: 39%;
  }
  .text5 {
    position: absolute;
    top: 65.5%;
    left: 71.3%;
  }
  .btn {
    width: 7.5rem;
    height: 2.25rem;
    line-height: 2.25rem;
    background: #ffffff;
    font-size: 0.9375rem;
    text-align: center;
    font-weight: 400;
    color: #1f10e0;
    border-radius: 3px;
    position: absolute;
    bottom: 2rem;
    background: #fff;
    left: 50%;

    transform: translate(-50%, 0);

    cursor: pointer;
    letter-spacing: 2px;
  }
  .btn:hover {
    background-color: #eeeeff;
  }
}
.bg2title {
  text-align: center;
  width: 100%;
  font-size: 1.65rem;
  font-family: opposm, sans-serif;
  font-weight: 500;
  color: #3b3b72;
  margin-top: 2.875rem;
  line-height: 2.0625rem;
}
.imgList {
  width: 100%;
  display: flex;
  // padding-left: 12%;
  // flex-wrap: wrap;
  // white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  margin-top: 2.125rem;
.lastGif {
    margin-right: 0.875rem !important;
  }
  .gifItem {
    width: 14.8125rem !important;
    height: 14.8125rem !important;
    position: relative;
    text-align: center;
    margin-left: 0.875rem;
    
    //  background-repeat:no-repeat;   /* 不会重复 */
    // background-position: center;   /* 是为了让图片放大的时候从中部放大，不会偏移 */
    // background-size: 100% 100%;
  
    .item {
      margin-top: 1.9rem;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 3.5rem;
        height: 3.5rem;
      }
      .line {
        margin: 0.6875rem auto;
        width: 9rem;
        height: 1px;
        background-color: #fff;
      }
      .title {
        font-size: 1.1875rem;

        color: #ffffff;
        margin-top: 0.9375rem;
        
        font-family: opposm, sans-serif;
        font-weight: bold;
      }
      .text {
        font-size: 0.875rem;
        text-align: justify;
        font-weight: 500;
        color: #ffffff;
        line-height: 1.25rem;
        width: 9rem;
        
        text-align-last: center;
      }
      
    }
  }

}
.bg2 {
  width: 100%;
  height: 29.375rem;
  margin-top: 3rem;
  background: url("../../assets/mobile/cooperation/bg1.png") no-repeat;
  position: relative;
  background-size: 100% 100%;

  .bgtitle {
    width: 100%;
    // position: absolute;
    // top: 2.25rem;
    // left: 50%;
    // transform: translate(-50%, 0);
    text-align: center;
    font-size: 1.65rem;
    font-family: opposm, sans-serif;
    font-weight: 500;
    color: #3b3b72;
    letter-spacing: 2px;
    margin-top: 2.2rem;
  }

  .bgList {
    position: relative;
    // position: absolute;
    // bottom: 2.5rem;
    margin: 0rem auto 0;
    width: 80%;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    .line1 {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 1px;
      height: 90%;
      background-color: #fff;
      transform: translate(-50%, );
    }
    .line2 {
      position: absolute;
      top: 55%;
      left: 50%;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }
    img {
      width: 3rem;
      height: 3rem;
    }
    .listItem {
      width: 38%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.1rem;
      margin-top: 2rem;
      .names {
        // margin-top: 0.5rem;
        color: #4e4d5e;
        font-size: 0.8125rem;

        text-align: justify;
      }
      .last {
        border-right: none;
      }
    }
  }
}
.forRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.3125rem;
  background: #3A1FFB;
  font-size:0.5rem;
  color: #ffffff;
}
</style>