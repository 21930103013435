<template>
  <div>
    <div class="header">
      <div class="imgs" @click="goback" >
        <!-- <img src="../../assets/mobile/arrow.png" alt @click="goback" /> -->
        <van-icon name="arrow-left" color="white"/>
      </div>
      <div class="title">合作咨询</div>
    </div>
    <div class="box">
      <div class="name">
        您的姓名
        <div>
          <input type="text"  v-model="form.name" placeholder="您的姓名"/>
        </div>
      </div>
      <div class="mobile">
        您的电话
        <div>
          <input type="text"  v-model="form.mobile" placeholder="您的电话"/>
        </div>
      </div>
      <div class="msg">
        您想咨询的内容
        <div>
           <textarea class="texts"  v-model="form.consult" placeholder="您想咨询的内容"></textarea>
        </div>
      </div>
      <div class="submit" @click="submits">立即提交</div>
    </div>

  </div>
</template>
<script>
import { submit } from "../../network/gobal";
import Vue from 'vue';
import { Toast } from 'vant';
import 'vant/lib/toast/style';
import { Icon } from 'vant';

Vue.use(Icon);
Vue.use(Toast);
export default {
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        consult: ""
      }
    };
  },
  methods:{
    goback(){
      this.$router.go(-1)
    },
    submits() {

      const ruleName = /^(?:[\u4e00-\u9fa5]+)(?:·[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/;
      const rulePhone = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;
     
      if (!this.form.name) {
        Toast("请输入您的姓名");
      } else if (!ruleName.test(this.form.name)) {
        Toast("请输入正确的姓名");
      } else if (!this.form.mobile) {
        Toast("请输入您的电话号码");
      } else if (!rulePhone.test(this.form.mobile)) {
        Toast("请输入正确的电话号码");
      }

      else if (!this.form.consult) {
       Toast("请输入您想咨询的内容");
      } else if (this.form.consult.length>250) {
        Toast("咨询的内容最长不能超过250个字符");
      }

      else {
        submit(this.form).then(res => {
          if (res.code == 200) {
            Toast('提交成功!\n快进商店工作人员将尽快与您取得联系。');
            this.form = {
              name: "",
              mobile: "",
              consult: ""

            };
           this.$router.go(-1)
          } else {
            Toast(res.msg);
          }
        });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
::-webkit-input-placeholder {
  color: #cccccc;
  font-family: opposr, sans-serif;
  letter-spacing: 1px;

}
::-webkit-textarea-placeholder {
  color: #cccccc;
  font-family: opposr, sans-serif;
  letter-spacing: 1px;

}
.header {
  height: 55px;
  width: 100%;
  background: #1f10e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;

  .imgs {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: 1rem;
    font-family: opposm, sans-serif;
    font-weight: 500;
    color: #ffffff;
  }
}
textarea{
  padding-left: 5px;
  margin-top: 10px;
  width: 98%;
  height: 6.25rem;
  white-space:pre-wrap;
 white-space:-moz-pre-wrap;
 white-space:-o-pre-wrap;
 word-wrap:break-word;
 border: 1px solid #B0B5BB;
border-radius: 2px;
-webkit-appearance:none;/*去除阴影边框*/
}
input{
 padding-left: 5px;
  width: 98%;

  height: 2.3125rem;
  border: 1px solid #B0B5BB;
border-radius: 2px;
margin: 10px 0;
-webkit-appearance:none;/*去除阴影边框*/
}
.box {
  padding: 0 8%;
  font-size: 0.95rem;
  font-family: opposm, sans-serif;
  font-weight: 400;
  color: #333333;
}
.name {
  margin-top: 5.625rem;
  margin-bottom: 5px;;
}
.mobile{
  margin-bottom: 5px;
}
.submit{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.7rem;
  background: #1F10E0;
  box-shadow: 0px 3px 6px 0px rgba(127, 121, 255, 0.47);
  border-radius: 2px;
 
  font-size: 1rem;
  letter-spacing: 2px;

  color: #FFFFFF;
  margin-top: 3.4375rem;
}
</style>
