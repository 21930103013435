<template>
  <div class="cooperation" @click.stop="close">
    <div>
      <img src="../assets/cooperation/banner.svg" alt style="width:100%" />
    </div>
    <div class="bg1">
      <div class="imgs" style="padding-top:5rem">
        <div class="img1" :class="{'actImg1':isShow==1}">
          <img src="../assets/cooperation/model1.png" alt style="width:100%" ref="img1" />
          <div class="box1">
            <div class="title">市场空间大</div>
            <div class="text">蓝海市场，数百万家零售店遍布大街小</div>
            <div class="text">巷皆为目标客户，各地市场亟待开发</div>
          </div>
        </div>
        <div class="img2" :class="{'actImg2':isShow==2}">
          <img src="../assets/cooperation/model2.png" alt style="width:100%;" ref="img2" />
          <div class="box2">
            <div class="title">收益多元化</div>
            <div class="text">模式创新科技加持，业务含金量高</div>
            <div class="text">坐享流水分佣、广告分成等多项持续性收益</div>
          </div>
        </div>
        <div class="img3" :class="{'actImg3':isShow==3}">
          <img src="../assets/cooperation/model3.png" alt style="width:100%;" ref="img3" />
          <div class="box3">
            <div class="title">回本周期快</div>
            <div class="text">万元投入成为区域独家渠道合作伙伴，</div>
            <div class="text">业务盈利模型清晰，全程一对一扶持</div>
          </div>
        </div>
        <div class="img4" :class="{'actImg4':isShow==4}">
          <img src="../assets/cooperation/model4.png" alt style="width:100%;" ref="img4" />
          <div class="box4">
            <div class="title">国家政策支持</div>
            <div class="text">多项政策支持智能化技术应用</div>
            <div class="text">鼓励改造现有门店，开设24小时营业便利店</div>
          </div>
        </div>
      </div>
    </div>
    <div class="earnings">
      <div class="earTitle">快进商店渠道合作伙伴拥有多项业务收益</div>
      <div class="text1">系统销售收入</div>
      <div class="text2">门店流水分佣</div>
      <div class="text3">流量广告分成</div>
      <div class="text4">供应链业务收益</div>
      <div class="text5">总部推广激励</div>
      <div class="btn" @click.stop="openDialog">立即申请</div>
    </div>
    <div style="width:100%" class="zc">我们为渠道合作伙伴切实提供全方位支持</div>
    <div class="imgList">
      <div
        v-for="(item,index) in list"
        :key="item.imgUrl"
        @mouseover="mouseOver(item,1,index)"
        @mouseleave="mouseLeave(item,1)"
        style="font-size:0;"
        class="gifItem"
        :style="{backgroundImage:'url('+item.url+')',}"
      >
      <div class="touch" v-show="index==touchIndex"></div>
        <div class="item" style="display：block" >
          <img :src="item.icon" alt />
          <div class="title">{{item.title}}</div>
          <div class="line"></div>
          <div class="text">{{item.text}}</div>
        </div>
        <!-- <img :src="item.url" alt style="display：block" /> -->
      </div>
    </div>
    <div class="bg2">
      <div class="bgtitle">披荆斩棘，一同前行，我们希望您：</div>
      <div class="bgList">
        <div v-for="(item,index) in list1" :key="item.name" class="listItem">
          <div>
            <img
              :src="item.url"
              alt
              @mouseover="mouseOver(item,2)"
              @mouseleave="mouseLeave(item,2)"
            />
          </div>
          <div class="names" :class="{'last':index==3}">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div style="width:100%">
      <img src="../assets/cooperation/bg3.png" alt style="width:100%" />
    </div>
    <gobalFooter style="margin-top:-6px"></gobalFooter>
    <dialogConsult
      :show="show"
      :title="title"
      @hideModal="hideModal"
      @submit="submit"
      @stopMp="stopMp"
    ></dialogConsult>
  </div>
</template>
<script>
import dialogConsult from "../components/dialogConsult";
import gobalFooter from "../components/gobalFooter";
export default {
  components: { gobalFooter, dialogConsult },
  data() {
    return {
      isShow:1,
      touchIndex:100,
      title: "合作咨询",
      show: false,
      time: null,
      list: [
        {
          index: 1000,
          url: require("../assets/cooperation/imgs/kj1.png"),
          imgUrl: require("../assets/cooperation/imgs/kj1.png"),
          icon: require("../assets/cooperation/imgs/icon1.png"),
          title: "项目培训支持",
          text: "提供一站式基础性培训，帮助合伙人团队快速熟悉业务"
        },
        {
          index: 1000,
          url: require("../assets/cooperation/imgs/kj2.png"),
          imgUrl: require("../assets/cooperation/imgs/kj2.png"),
          icon: require("../assets/cooperation/imgs/icon2.png"),
          title: "市场开拓支持",
          text: "从拓展话术到销售技能，为团队提供完善的业务技能培训"
        },
        {
          index: 1000,
          url: require("../assets/cooperation/imgs/kj3.png"),
          imgUrl: require("../assets/cooperation/imgs/kj3.png"),
          icon: require("../assets/cooperation/imgs/icon3.png"),
          title: "业务运营支持",
          text: "丰富的运营活动助力业务开展,定期分析数据，量身定制方案"
        },
        {
          index: 1000,
          url: require("../assets/cooperation/imgs/kj6.png"),
          imgUrl: require("../assets/cooperation/imgs/kj4.png"),    
          icon: require("../assets/cooperation/imgs/icon4.png"),
          title: "软硬件维保支持",
          text: "自有的强大研发团队保障软硬件更新及维护，让您售后无忧"
        },
        {
          index: 1000,
          url: require("../assets/cooperation/imgs/kj5.png"),
          imgUrl: require("../assets/cooperation/imgs/kj5.png"), 
          icon: require("../assets/cooperation/imgs/icon5.png"),
          title: "客户服务支持",
          text: "总部客服团队全年365天无休待命，全力保障消费者体验"
        },
        {
          url: require("../assets/cooperation/imgs/kj4.png"),
          imgUrl: require("../assets/cooperation/imgs/kj6.png"),
          icon: require("../assets/cooperation/imgs/icon6.png"),
          index: 1000,
          title: "品牌合作支持",
          text: "平台背书高效对接广告主等合作方，无差别引入合伙人区域"
        }
      ],
      list1: [
        {
          name:
            "看好智能零售行业的市场发展前景，坚信科技的力量可以给人们带来美好的生活",
          url: require("../assets/cooperation/icon1.png"),
          imgUrl: require("../assets/cooperation/icon1.png"),
          gifUrl: require("../assets/cooperation/icon1.gif")
        },
        {
          name:
            "了解并认同快进商店企业文化，愿长期合作，持续经营，有责任感、事业心",
          url: require("../assets/cooperation/icon2.png"),
          imgUrl: require("../assets/cooperation/icon2.png"),
          gifUrl: require("../assets/cooperation/icon2.gif")
        },
        {
          name:
            "对快进商店业务模式充分认可，可以理性判断，认真选择，且具有较强执行力",
          url: require("../assets/cooperation/icon3.png"),
          imgUrl: require("../assets/cooperation/icon3.png"),
          gifUrl: require("../assets/cooperation/icon3.gif")
        },
        {
          name:
            "具备一定资金实力和管理经验，熟悉当地市场，固定办公场所和成熟业务团队更佳",
          url: require("../assets/cooperation/icon4.png"),
          imgUrl: require("../assets/cooperation/icon4.png"),
          gifUrl: require("../assets/cooperation/icon4.gif")
        }
      ]
    };
  },
  mounted() {
    this.isElementNotInViewport(this.$refs.img1);
  },
  methods: {
    hideModal() {
      // 取消弹窗回调
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },

    submit() {
      // 确认弹窗回调
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },
    close() {
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },
    stopMp() {
      this.show = true;
    },
    openDialog() {
      //  document.body.parentNode.style.overflow="hidden";//隐藏且禁用
      this.show = true;
    },
    mouseOver(item, num,index) {
      
      if (num == 1) {
      this.touchIndex=index
      } else {
        item.url = item.gifUrl;
        setTimeout(() => {
          item.url = item.imgUrl;
        }, 1100);
      }
    },
    mouseLeave(item, index) {
      if (index != 2) {
       this.touchIndex=100
      }
    },
    isElementNotInViewport(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();
      });
    }
  }
};
</script>
<style lang='scss' scoped>
.cooperation {
  width: 100%;

  margin-top: 68px;
  font-family: opposr,sans-serif;
}
@keyframes change {
  from {
    
    margin-top: 3.125rem;
  }
  to {
   
   margin-top: 1.25rem;
  }
}
.actImg1{
  animation: change 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}
.bg1 {
  background: url("../assets/cooperation/bg1.png") no-repeat;
  width: 100%;
  position: relative;
  height: 85rem;
  .imgs {
    text-align: center;
    // animation: change 1s;
    // animation-fill-mode: forwards;
    // -webkit-animation-fill-mode: forwards;
  }
  .img1,
  .img2,
  .img3,
  .img4 {
    margin: -0.625rem auto;
    position: relative;
    width: 75%;
  }
  .img3 {
    margin-top: -1.3rem;
  }
  .box1 {
    position: absolute;
    top: 52%;
    letter-spacing: 2px;
    left: 55%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1.9rem;
     
      font-weight: 500;
      color: #3b3b72;
      line-height: 5rem;
      text-align: left;
      font-family: opposm,sans-serif;
    }
    .text {
      text-align: left;
      font-size: 1.2rem;
    
      font-weight: 400;
      color: #808080;
    }
  }
  .box2 {
    position: absolute;
    top: 48%;
    letter-spacing: 2px;
    right: 55%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1.9rem;
     font-family: opposm,sans-serif;
      font-weight: 500;
      color: #3b3b72;
      line-height: 5rem;
      text-align: right;
    }
    .text {
      text-align: right;
      font-size: 1.2rem;
     
      font-weight: 400;
      color: #808080;
    }
  }
  .box3 {
     position: absolute;
    top: 47%;
    letter-spacing: 2px;
    left: 55%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1.9rem;
      font-family: opposm,sans-serif;
      font-weight: 500;
      color: #3b3b72;
      line-height: 5rem;
      text-align: left;
    }
    .text {
      text-align: left;
      font-size: 1.2rem;
     
      font-weight: 400;
      color: #808080;
    }
  }
  .box4 {
    position: absolute;
    top: 47%;
    letter-spacing: 2px;
    right: 55%;
    -webkit-transform: translateY(-50%);
    .title {
      font-size: 1.9rem;
     font-family: opposm,sans-serif;
      font-weight: 500;
      color: #3b3b72;
      line-height: 5rem;
      text-align: right;
    }
    .text {
      text-align: right;
      font-size: 1.2rem;
     
      font-weight: 400;
      color: #808080;
    }
  }
}
.earnings {
  background: url("../assets/cooperation/earnings.png") no-repeat;
  width: 100%;
  height: 46.875rem;
  position: relative;
  background-size: 100% 100%;
  padding-top: 4.8rem;
  font-size: 2rem;
 font-family: opposm,sans-serif;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
  .earTitle {
    letter-spacing: 2px;
    text-align: center;
    font-size: 2rem;
    
    font-weight: 500;
    color: #ffffff;
    line-height: 2rem;
  }
  .text1 {
    position: absolute;
    top: 34%;
    left: 33%;
  }
  .text2 {
    position: absolute;
    top: 34%;
    left: 56.7%;
  }
  .text3 {
    position: absolute;
    top: 66%;
    left: 21.4%;
  }
  .text4 {
    position: absolute;
    top: 66%;
    left: 44.5%;
  }
  .text5 {
    position: absolute;
    top: 66%;
    left: 69.7%;
  }
  .btn {
    font-size: 1.375rem;
    font-family: opposm,sans-serif;
    font-weight: 500;

    width: 11.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
    border-radius: 0.125rem;
    background-color: #fff;
    position: absolute;
    bottom: 4rem;
    color: #1f10e0;
    left: 50%;
    transform: translate(-50%, 0);
    
    cursor: pointer;
    letter-spacing: 2px;
  }
  .btn:hover{
    background-color: #eeeeff;
  }
}
.zc {
  text-align: center;
  font-size: 2rem;
 font-family: opposm,sans-serif;
  font-weight: 500;
  color: #3b3b72;
  letter-spacing: 2px;
  margin: 6.1875rem 0 4.125rem;
}
.bg2 {
  width: 100%;
  height: 37.5rem;
  margin-top: 100px;
  background: url("../assets/cooperation/bg2.png") no-repeat;
  position: relative;
  background-size: 100% 100%;

  .bgtitle {
    position: absolute;
    top: 6rem;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-size: 2rem;
    font-family: opposm,sans-serif;
    font-weight: 500;
    color: #3b3b72;
    letter-spacing: 2px;
  }
  .bgList {
    position: absolute;
    bottom: 9.375rem;
    width: 72%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-around;
    text-align: center;
    img {
      width: 8.1875rem;
      height: 8.1875rem;
    }
    .listItem {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.875rem;

      .names {
        color: #4e4d5e;
        font-size: 1.1rem;
        
        text-align: left;
        border-right: 2px solid #ffffff;
        padding: 0 40px;
      }
      .last {
        border-right: none;
      }
    }
  }
}
.imgList {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  margin: 20px auto 0 auto;
  img {
    width: 25.1875rem;
    height: 25.125rem;
  }
  .gifItem:hover{
    background-size: 110% 110%;
    background-color: #1f10e0 !important;

  }
  .gifItem {
     transition: all 1.2s;
    -moz-transition:all 1.2s;
    -webkit-transition:all 1.2s;
    -o-transition:all 1.2s;
   width: 25.1875rem;
    height: 25.125rem;
    position: relative;
    text-align: center;
     background-repeat:no-repeat;   /* 不会重复 */
    background-position: center;   /* 是为了让图片放大的时候从中部放大，不会偏移 */
    background-size: 100% 100%;
    .touch{
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #1f10e0 !important;
      opacity: 0.6;
    }
    .item {
      margin-top: 6rem;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 3.75rem;
        height: 3.75rem;
        cursor: pointer;
        transition: all 0.6s;
      }
      .line {
        margin: 1.375rem auto;
        width: 17.6875rem;
        height: 1px;
        background-color: #fff;
      }
      .title {
        font-size: 1.4rem;

        
        color: #ffffff;
        margin: 1rem;
        letter-spacing: 2px;
        font-family: opposm,sans-serif;
      }
      .text {
        font-size: 1.1rem;
        
        font-weight: 500;
        color: #ffffff;
        line-height: 1.7rem;
        width: 17.6875rem;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
