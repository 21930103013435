<template>
    <div class="AutoSlide" :style="{'marginTop':colortype==2?equipmentType=='pc'?'0.8125rem':'0.2rem':''}">
        <div class="autoBox" ref="autoBox" >

            <div class="indemo" :style="{width: equipmentType=='pc'?'1200%':'2400%'}">

                <div class="autoBox1" ref="autoBox1">

                   <img v-for="(item,index)  in imglist" :style="{width: equipmentType=='pc'?'20.625rem':'5.5rem',height:equipmentType=='pc'?'15.625rem':'6.25rem',marginRight:equipmentType=='pc'?'.75rem':'.2rem'}"  lazy :key="index" :src="item" border="0" />

                </div>

                <div class="autoBox2" ref="autoBox2">   
                    
                </div>
            </div>
        </div>



    </div>
</template>


<script>
export default {
    data(){
        return {
            
            tab:null,
            tab1:null,
            tab2:null,
            MyMar:null
        }
    },
    mounted(){
        this.init();
    },
    props:{
         speed:{
             type:Number,
             default:10
         },
        list:{
            type:Array,
            default:[]
        },
        colortype:{
            type:Number,
            default:1
        },
        equipmentType:{
            type:String,
            default:'pc'
        }
    },
    computed:{
        imglist(){
           let arr = [];
           let start = this.list[0];
           let end = this.list[1];
           let equipmentType = this.equipmentType;
           while(start<end) {
               arr.push(require(`../assets/${equipmentType=='pc'?'':'mobile/'}product/PCUpdete/${start}.jpg`));
               start++;
           }
           return arr;
        },
        
    },
    methods:{
        init(){
            
            if (!this.MyMar) {
                this.tab=this.$refs['autoBox'];
                this.tab1=this.$refs['autoBox1'];	
                this.tab2=this.$refs['autoBox2'];
                this.tab2.innerHTML = this.tab1.innerHTML;
                if (this.colortype == 2) {
                    let width = (document.body.clientWidth / 1920) * 500
                    this.tab.scrollLeft+= this.equipmentType == 'pc'?width:250;
                }
                this.MyMar=setInterval(this.Marquee,this.speed);
            }
        },
        Marquee(){
            

            if(this.tab2.offsetWidth-this.tab.scrollLeft<=0){    
                
                this.tab.scrollLeft-=this.tab1.offsetWidth;

            }else{
               
                this.tab.scrollLeft++;
                
            }

        }
    },
    
}   
</script>

<style lang="scss" scoped>
img {
    border: none;
}
.AutoSlide {
    margin: 0px auto;
    width: 100%;
    overflow: hidden;
}

.autoBox {
    overflow:hidden;
    width: 100%;
    .indemo {
        float: left;

         /*宽度足够大，使能形成滚动的条件*/

    }
    .autoBox1 {
        display: flex;
        align-items: center;
        float: left;
        border: none;
    }

    .autoBox2 {
        display: flex;
        align-items: center;
        float: left;
        border: none;
    }
}




</style>