<template>
  <div class="devplatform" @click.stop="close">
    <div class="progressive">
      <div class="float-box">
        <div class="title">快进商店数据开放平台</div>
        <div class="desc">数字化赋能零售企业</div>
        <div class="desc">共同打通智能零售全链路发展</div>
        <div class="btn" style="margin-top: 6.1875rem" @click.stop="openDialog">
          立即加入
        </div>
      </div>
      <img :src="banner.preview" alt style="width: 100%" />
    </div>
    <div class="cooperation">
      <div class="title">商务合作</div>
      <div class="desc">高效合作流程，助您快速开展合作</div>
      <div class="imgbox">
        <img
          v-for="(item, index) in imgList"
          :key="index"
          :src="item.url"
          :alt="item.alt"
        />
      </div>
      <div style="display: flex; justify-content: center">
        <div class="btn" style="margin-top: 3.9375rem" @click.stop="openDialog">
          立即申请
        </div>
      </div>
    </div>
    <dialogConsult
      :show="show"
      :title="title"
      @hideModal="hideModal"
      @submit="submit"
      @stopMp="stopMp"
    />
    <gobalFooter></gobalFooter>
  </div>
</template>

<script>
import gobalFooter from "../components/gobalFooter";
import dialogConsult from "../components/dialogConsult.vue";
export default {
  components: { gobalFooter, dialogConsult },
  data() {
    return {
      title: "商务合作",
      show: false,
      banner: {
        preview: require("../assets/devplatform/banner.png"),
      },
      imgList: [
        {
          url: require("../assets/devplatform/img1.png"),
          alt: "合作申请",
        },
        {
          url: require("../assets/devplatform/img2.png"),
          alt: "资格审核",
        },
        {
          url: require("../assets/devplatform/img3.png"),
          alt: "业务沟通",
        },
        {
          url: require("../assets/devplatform/img4.png"),
          alt: "服务签订",
        },
        {
          url: require("../assets/devplatform/img5.png"),
          alt: "落地开展",
        },
      ],
    };
  },
  methods: {
    hideModal() {
      // 取消弹窗回调
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },

    submit() {
      // 确认弹窗回调
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },
    close() {
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },
    stopMp() {
      this.show = true;
    },
    openDialog() {
      //  document.body.parentNode.style.overflow="hidden";//隐藏且禁用
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.devplatform {
  width: 100%;
  .btn {
    width: 12.5rem;
    height: 3.75rem;
    line-height: 3.75rem;
    background: #1f10e0;
    color: #ffffff;
    font-weight: 400;
    font-size: 1.375rem;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    font-family: opposm, sans-serif;
    letter-spacing: 2px;
  }
  .progressive {
    position: relative;
    // z-index:99;
    width: 100%;
    height: 49.4375rem;
    img {
      width: 100%;
      height: 100%;
    }
    .float-box {
      position: absolute;
      top: 13.8125rem;
      left: 11.9375rem;
      width: 31.125rem;
      min-height: 300px;
      .title {
        margin-bottom: 2.25rem;
        font-size: 3rem;
        font-family: opposm;
        font-weight: 500;
        color: #3b3b72;
      }
      .desc {
        // margin-bottom: 20px;
        font-size: 1.5rem;
        font-family: opposr;
        font-weight: 400;
        color: #4e4d5e;
      }
    }
  }
  .cooperation {
    width: 100%;
    height: 48.25rem;
    background: #fff;
    .title {
      margin-top: 5.75rem;
      text-align: center;
      font-size: 3rem;
      font-family: opposm;
      font-weight: 500;
      color: #3b3b72;
    }
    .desc {
      margin-top: 1.875rem;
      text-align: center;
      font-size: 1.5rem;
      font-family: opposr;
      font-weight: 400;
      color: #4e4d5e;
    }
    .imgbox {
      margin-top: 3.25rem;
      display: flex;
      justify-content: center;
      img {
        margin: 0px 1.0625rem;
        width: 18.6875rem;
        height: 21.8125rem;
      }
    }
  }
}
</style>