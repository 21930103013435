<template>
  <div class="home" @click.stop="close">
    <!-- <div class="bannerVideo" v-show="isBanner">
      <video style="width:100%;height:53.875rem" id="myVideo" class="video-js">
        <source src="https://cdn.ulandian.com/kuaijin/www/lv_0_20211223154410.mp4" type="video/mp4" />
      </video>
      <div class="bannerImg"></div>
      <img src="../assets/home/bannerImg.png" alt style="width:100%;z-index:999" class="bannerImgs" />
    </div>-->
    <el-carousel
      height="54rem"
      @change="changeSwiper"
      :interval="interval"
      style="width:100%"
      trigger="click"
      ref="carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="(item, index) in list"
        :key="index"
        :style="{backgroundImage: 'url(' + item.url + ')',height:'54rem',backgroundSize:'100% 100%'}"
      >
        <div style="width:100%;height:100%" :style="{'cursor':index!=0?'pointer':''}" @click="clickGo(item,index)">
              <div class="bannerVideo" v-if="item.isVideo">
              <!-- <video
                style="width:100%;height:54rem;object-fit: fill;"
                id="myVideo"
                class="video-js"
                loop="loop"
              >
                <source
                  src="https://cdn.ikuaijin.com/kuaijin/www/lv_0_20211224183629.mp4"
                  type="video/mp4"
                />
              </video>
              <div class="bannerImg"></div> -->
            </div>
            <!-- <video
              style="width:100%;height:53rem"
              id="myVideo"
              class="video-js"
              v-if="item.isVideo"
              loop="loop"
            >
              <source
                src="https://cdn.ulandian.com/kuaijin/www/lv_0_20211223154410.mp4"
                type="video/mp4"
              />
            </video>-->
            <div class="bannerText">
              <div class="bannerTitle">{{item.text1}}</div>
              <div class="bannerTitle">{{item.text2}}</div>
              <div class="bannerTitle">{{item.text3}}</div>
              <div class="bannerRed"></div>
              <div class="bannerMsg">{{item.msg1}}</div>
              <div class="bannerMsg">{{item.msg2}}</div>
            </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- <swiper ref="swiper" style="margin-top:-2px">
      <swiper-item
        v-for="(item, index) in list"
        :key="index"
        :style="{backgroundImage: 'url(' + item.url + ')',height:'862px',backgroundSize:'100% 100%' }"
      >
        <video
          style="width:100%;height:53.875rem"
          id="myVideo"
          class="video-js"
          v-if="item.isVideo"
          loop="loop"
        >
          <source
            src="https://cdn.ulandian.com/kuaijin/www/lv_0_20211223154410.mp4"
            type="video/mp4"
          />
        </video>
        <img :src="item.text" alt class="bannerText" style="width:100%;height:100%" v-else />
      </swiper-item>
    </swiper>-->
    <div ref="start" style="margin-top:0" class="startGif">
      <div class="leftStart">
        <img src="../assets/home/startimg.png" alt="快进商店KJ-Store" title="快进商店KJ-Store"  v-show="isShow==2" />
        <img src="../assets/home/imgCenter.gif" alt="无人便利店" title="无人便利店" v-show="isShow==1" />
        <img src="../assets/home/img2.png" alt="24小时云值守" title="24小时云值守" v-show="isShow==3" />
      </div>
      <div class="rightStart">
        <div style="width:100%;height:1px"></div>
        <div v-show="isShow==1||isShow==2">
          <div class="startTitle" :class="{'actRight':isShow==1||isShow==2}">
            解决商户
            <span
              style="font-size: 3.5rem;font-weight: 500;color: #1F10E0;font-family:opposm;margin-left:-0.625rem"
            >守店之痛</span>
            <div>助力门店降本增效</div>
          </div>

          <div class="startLine"></div>
          <div class="startText">快进商店为快进时代（厦门）科技有限公司旗下，专注于传统线下商户数字化、无人化升级改造的智能零售平台。</div>
          <div
            class="startText2"
          >快进商店通过自主研发的全套技术解决方案，运用智能硬件+SaaS平台的双向维度输出，帮助国内数百万家零售小店实现降本增收、时间自由；让广大消费者实现真正的“即时性”消费，每时每刻都能享受到快进商店带来的便捷服务。</div>
        </div>
      </div>

      <!-- <img src="../assets/home/imgstart.png" alt style="width:100%" v-show="isShow==3" />
      <img src="../assets/home/img1.png" alt style="width:100%;margin-left:-2px" v-show="isShow==2" />
      <img src="../assets/home/img1.gif" alt style="width:100%" v-show="isShow==1" />-->
    </div>
    <div class="bg1" ref="end">
      <div style="width:100%;height:1px"></div>
      <div class="bg1Box">
        深耕行业数年，全套自研系统，技术实力保障
        <div style="margin-top:-0.7rem">
          <img src="../assets/home/line.png" alt />
        </div>
      </div>
      <!-- <img src="../assets/home/bg1.png" alt style="width:100%" /> -->
      <div class="a">
        <img src="../assets/home/text.gif" alt v-show="isShow1==1" />
        <img src="../assets/home/text.png" alt v-show="isShow1==2" />
      </div>
      <div class="moreBtn" @click.stop="openDialog">了解更多</div>
    </div>
    <div class="mall" style="margin-top:-3px">
      <div style="width:100%;height:1px"></div>
      <div class="mallBox">
        <div v-for="item in mall" :key="item.name" class="mallItem">
          <img :src="item.url" alt />
          <div class="mallname">{{item.name}}</div>
        </div>
      </div>
      <div class="mallTitle">快进商店产品适用于各类型便利店、零售店，科技赋能助力门店轻松经营</div>
    </div>
    <div class="bg2">
      <div style="width:100%;height:1px"></div>
      <div class="bgTitle">
        <div>
          <img src="../assets/home/left.png" alt />
        </div>
        <div style="margin:0.5rem 1.75rem 0">快进商店五大服务保障</div>
        <div>
          <img src="../assets/home/right.png" alt />
        </div>
      </div>
      <div class="itemList">
        <div v-for="(item,index) in list1" :key="item.name" class="itemIcon">
          <div>
            <div>
              <img
                :src="item.url"
                alt
                @mouseover="mouseOver(item,index)"
                @mouseleave="mouseLeave(item)"
              />
            </div>
            <div class="item">
              <div class="name" style="font-family:opposr">{{item.name}}</div>
              <div class="text" style="font-family:opposr">{{item.name1}}</div>
            </div>
          </div>
          <div class="line" v-if="index!=0"></div>
        </div>
      </div>
    </div>
    <gobalFooter style="margin-top:8px"></gobalFooter>
    <dialogConsult
      :show="shows"
      :title="title"
      @hideModal="hideModal"
      @submit="submit"
      @stopMp="stopMp"
    ></dialogConsult>
  </div>
</template>


<script>
// @ is an alias to /src
import dialogConsult from "../components/dialogConsult";
import { Swiper, SwiperItem } from "../components/swiper";
import gobalFooter from "../components/gobalFooter";
export default {
  name: "Home",
  components: { Swiper, SwiperItem, gobalFooter, dialogConsult },
  data() {
    return {
      title: "合作咨询",
      shows: false,
      interval: 4000,
      mall: [
        { url: require("../assets/home/mall1.png"), name: "传统夫妻店" },
        { url: require("../assets/home/mall2.png"), name: "品牌加盟店" },
        { url: require("../assets/home/mall3.png"), name: "连锁直营店" },
        { url: require("../assets/home/mall4.png"), name: "药店等" }
      ],
      isBanner: true, // 是否显示banner图
      isVideo: true, // 是否显示视频
      isShow: 3,
      isShow1: 3,
      actindex: 1,
      indexs: 1,
      imgUrl1: require("../assets/home/img1.png"),
      list: [
        // {
        //   url: "https://cdn.ikuaijin.com/kuaijin/www/lv_0_20211223154410.mp4",
        //   isVideo: true,
        //   text: require("../assets/home/bannerImg.png"),
        //   text1: "开店做生意",
        //   text2: "老板不用那么累",
        //   msg1: "有人+无人模式智能门店",
        //   msg2: "24小时营业，降本增收",
        //   title:"",
        //   path:"/home"
        // },
        {
          url: require("../assets/home/banner.png"),
          text:  require("../assets/home/bannerImg.png"),
          text1: "不想苦守门店",
          text2: "就找快进商店",
          msg1: "有人+智能模式智能门店",
          msg2: "24小时营业，降本增收",
          title:"快进商店KJ-Store,真正的零售科技",
          path:"/home"
        },
        {
          url: require("../assets/home/banner1.png"),
          text: require("../assets/home/bannerText1.png"),
          text1: "开店做生意",
          text2: "老板不用那么累",
          msg1: "千元成本，48小时内完成升级",
          msg2: "让经营超轻松",
          title:"快进商店KJ-Store,真正的零售科技",
          path:"/kjProduct"
        },
        {
          url: require("../assets/home/bannerTow.jpg"),
          text: require("../assets/home/bannerText2.png"),
          text1: "诚邀有志之士",
          text2: "共享时代红利",
          msg1: "智能化、无人化零售大势所趋",
          msg2: "把握先机，立即行动",
          title:"快进商店KJ-Store,智能化、无人化零售大势所趋",
          path:"/cooperation"
        },
        {
          url: require("../assets/home/banner3.png"),
          text: require("../assets/home/bannerText3.png"),
          text1: "心怀梦想",
          text2: "脚踏实地",
          text3: "快进有我",
          msg1: `八年连续创业团队`,
          msg2: "生而骄傲只因不忘初心",
          title:`快进商店KJ-Store,八年连续创业团队`,
          path:"/aboutUs"
        }
      ],
      list1: [
        {
          name: "小程序、后台系统",
          name1: "迭代升级永久免费",
          url: require("../assets/home/icon1.png"),
          imgUrl: require("../assets/home/icon1.png"),
          gifUrl: require("../assets/home/icon1.gif")
        },
        {
          name: "品牌硬件全套质保",
          name1: "24小时内服务响应",
          url: require("../assets/home/icon2.png"),
          imgUrl: require("../assets/home/icon2.png"),
          gifUrl: require("../assets/home/icon2.gif")
        },
        {
          name: "Ai算法精准视觉识别",
          name1: "黑名单系统风控保障",
          url: require("../assets/home/icon3.png"),
          imgUrl: require("../assets/home/icon3.png"),
          gifUrl: require("../assets/home/icon3.gif")
        },
        {
          name: "值守期间商品缺失",
          name1: "平台追回、货损必赔",
          url: require("../assets/home/icon4.png"),
          imgUrl: require("../assets/home/icon4.png"),
          gifUrl: require("../assets/home/icon4.gif")
        },
        {
          name: "一对一专属业务顾问",
          name1: "全年365天总部客服",
          url: require("../assets/home/icon5.png"),
          imgUrl: require("../assets/home/icon5.png"),
          gifUrl: require("../assets/home/icon5.gif")
        }
      ]
    };
  },

  mounted() {
    // this.initVideo();
    this.isElementInViewport(this.$refs.start);
    this.isElementInViewports(this.$refs.end);
    this.$refs.carousel.handleMouseEnter = () => { };
    // var md = document.getElementsByTagName("video")[0];
    // if (md.ended) {
    //   console.log("结束");
    // }
    // md.addEventListener("ended", function() {

    // });
    // setTimeout(() => {
    //   this.interval = 4000;
    // }, 4000);
  },
  methods: {
    hideModal() {
      // 取消弹窗回调
      this.shows = false;
    },
    next() {},
    submit() {
      // 确认弹窗回调
      this.shows = false;
    },
    close() {
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.shows = false;
    },
    stopMp() {
      this.shows = true;
    },
    openDialog() {
      // document.body.parentNode.style.overflow="hidden";//隐藏且禁用
      this.shows = true;
    },
    changeSwiper(item) {
      this.interval = 4000;
      // if (item == 0) {
      //   this.interval = 20000;
      //   setTimeout(() => {
      //     this.interval = 4000;
      //   }, 20000);
      // } else {
      //   this.interval = 4000;
      // }
    },
    initVideo() {
      let that = this;
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: false,
        //自动播放属性,muted:静音播放
        autoplay: "muted",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",

        controlBar: {
          playToggle: false,
          currentTimeDisplay: false,
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          volumePanel: {
            inline: false
          }
        }
      });

      myPlayer.on("ended", function() {
        // that.isBanner = false;
        that.interval = 3000;
        that.$refs.carousel.next();
      });
    },

    mouseOver(item, index) {
      item.url = item.gifUrl;
      if (index == 3) {
        setTimeout(() => {
          item.url = item.imgUrl;
        }, 1200);
      } else {
        setTimeout(() => {
          item.url = item.imgUrl;
        }, 900);
      }

    },
    mouseLeave(item) {
      // item.url = item.imgUrl;
    },
    isElementInViewport(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();

        if (rect.top < 500 && that.indexs == 1) {
          that.indexs = 2;
          that.isShow = 1;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            that.isShow = 2;
          }, 900);
        }
      });
    },
    isElementInViewports(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();

        if (rect.top < 560 && that.actindex == 1) {
          that.actindex = 2;
          that.isShow1 = 1;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            that.isShow1 = 2;
          }, 2850);
        }
      });
    },
    stopTimer() {
      this.$refs.swiper.stopTimer();
    },
    startTimer() {
      if (this.$refs.swiper) {
        this.$refs.swiper.startTimer();
      }
    },
    clickGo(item,index) {
      if (index == 0) return;
      this.$router.push(item.path);
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  font-family: opposr, sans-serif;
}
.bannerVideo {
  position: relative;
}
@keyframes changeRight {
  from {
    margin-top: 20rem;
  }
  to {
    margin-top: 18.5rem;
  }
}
.actRight {
  animation: changeRight 0.6s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.bannerImg {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #1f10e0;
  opacity: 0.4;
}
.startGif {
  width: 100%;
  height: 62.5rem;
  background: url("../assets/home/startbg.png") no-repeat;
  // background-size: cover;

  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  .leftStart {
    img {
      width: 56.875rem;
    }
  }
  .rightStart {
    width: 58.25rem;
    position: relative;
    .startTitle {
      margin-top: 15.625rem;
      font-size: 2.8rem;
      letter-spacing: 2px;
      font-weight: 500;
      color: #3b3b72;
      line-height: 4rem;
      font-family: opposm, sans-serif;
    }
    .startLine {
      position: absolute;
      right: 0;
      top: 45%;
      // margin: 1.875rem 0;
      width: 100%;
      height: 2px;
      background-color: #1f10e0;
    }
    .startText {
      margin-top: 3rem;
      width: 65%;
      font-size: 1.25rem;
      letter-spacing: 2px;
      font-weight: 400;
      color: #4e4d5e;
      line-height: 2rem;
      text-align: justify;
      font-family: opposr, sans-serif;
    }
    .startText2 {
      margin-top: 1rem;
      width: 65%;
      font-size: 1.25rem;
      letter-spacing: 2px;
      font-weight: 400;
      color: #4e4d5e;
      line-height: 2rem;
      text-align: justify;
    }
  }
}
.bannerImgs {
}
.bannerText {
  position: absolute;
  bottom: 27%;
  left: 17%;
  .bannerTitle {
    font-size: 4.3rem;
    font-family: opposm, sans-serif;

    color: #ffffff;
    line-height: 6.0625rem;
  }
  .bannerRed {
    width: 2.5rem;
    height: 0.5rem;
    background-color: #ff3162;
    margin: 2.875rem 0 2.5rem;
  }
  .bannerMsg {
    font-size: 1.3125rem;
    font-family: opposm, sans-serif;
    font-weight: 500;
    color: #ffffff;
  }
}
.bg1 {
  width: 100%;
  height: 72rem;
  position: relative;
  // display: flex;
  // justify-content: center;
  background: url("../assets/home/bg1.png") no-repeat;
  // background-size: cover;

  background-size: 100% 100%;
  .moreBtn {
    cursor: pointer;
    position: absolute;
    left: 50%;
    bottom: 6.9375rem;
    transform: translate(-50%, 0);
    text-align: center;
    width: 11.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    background: #1f10e0;
    font-size: 1.375rem;

    font-weight: 400;
    color: #ffffff;
    border-radius: 2px;
  }
  .moreBtn:hover {
    background-color: #4234fa;
  }
  .bg1Box {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 500;
    color: #3b3b72;
    margin-top: 7.5rem;
    letter-spacing: 2px;
    font-family: opposm, sans-serif;
    img {
      width: 68.25rem;
      height: 3px;
    }
  }
  .a {
    position: absolute;
    left: 50%;
    bottom: 15rem;
    transform: translate(-50%, 0);
    img {
      height: 40.625rem;
    }
  }
}
.mall {
  width: 100%;
  background: url("../assets/home/type.png") no-repeat;
  height: 39rem;
  background-size: 100% 100%;
  padding-top: 6.25rem;
  .mallBox {
    margin: 2.2rem auto 0;
    width: 70%;
    display: flex;
    flex: 1;
    .mallItem {
      position: relative;
      .mallname {
        position: absolute;
        bottom: 10%;
        left: 50%;
        font-size: 1.625rem;

        font-weight: 500;
        color: #ffffff;
        transform: translate(-50%, 0);
        font-family: opposm, sans-serif;
      }
    }
    img {
      width: 20.875rem;
      height: 23.75rem;
    }
  }
  .mallTitle {
    letter-spacing: 2px;
    font-size: 1.6875rem;
    text-align: center;
    font-weight: bold;
    color: #4e4d5e;
    margin-top: 3.4375rem;
    font-family: opposr, sans-serif;
  }
}
.bg2 {
  margin-top: -5px;
  width: 100%;

  height: 30.375rem;

  // background: url("../assets/home/bg2.png") no-repeat;
  // background-size: cover;
  position: relative;
  // background-size: 100% auto;

  .bgTitle {
    display: flex;
    font-size: 1.75rem;
    justify-content: center;
    font-weight: 500;
    color: #3b3b72;
    margin-top: 5.625rem;
    letter-spacing: 2px;
    font-family: opposm, sans-serif;
    img {
      width: 28.875rem;
      height: 3px;
    }
  }
  .itemList {
    width: 73%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 6.8rem;
    font-family: opposr, sans-serif;
    img {
      width: 8.5rem;
      height: 8.5rem;
    }
  }
  .itemIcon {
    position: relative;
    width: 20%;
    text-align: center;
    .itemBox {
      display: flex;
    }
    .item {
    }
    .name {
      margin: 0 auto;

      letter-spacing: 3px;
      font-family: opposr, sans-serif;
      color: #4e4d5e;
      font-size: 1.125rem;
    }
    .text {
      margin-top: 5px;
      font-family: opposr, sans-serif;
      color: #4e4d5e;
      font-size: 1.125rem;
    }
    .line {
      position: absolute;
      bottom: 0;
      width: 1px;
      height: 3.15rem;
      background-color: #adabc3;
    }
  }
}
</style>
