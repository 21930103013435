<template>
    <div class="minikj_error">
        <div class="top-box">
            <!-- <img class="img-border" src="../../assets/minikj/top_border.png" style="position: absolute;top: 6.2687rem;left:2.8331rem"> -->

            <img class="text-message" src="../../assets/minikj/text.svg" >
            <!-- <div class="message-text" style="position:absolute;left: 2.8125rem;top: 8.375rem;">
                <div>请您使用</div>
                <div>微信扫描二维码</div>
                <div>方可进行购物哦</div>
            </div>
            <div class="English-text">
                <div>Please use WeChat to scan QR</div>
                <div>code before shopping</div>
            </div> -->
            <img class="img-bg" src="../../assets/minikj/top.png">
        </div>
        <div class="bottom-box" style=" position:absolute;bottom: 0px;z-index: -1;width: 100%; height:58vh;">
            <img class="logo" src="../../assets/minikj/logo.svg">
            <img class="phone" src="../../assets/minikj/phone.svg">
            <img src="../../assets/minikj/bottom.png" style="width:100%;height:100%">
        </div>
    </div>
</template>

<script>
export default {
    name:'minikj_error',
    data(){
        return {
           
        }   
    },
    mounted() {
         
    },
}
</script>
<style lang="scss" scoped>
   
    * {
        padding:0px;
        margin: 0px;
    }
    .minikj_error {
        width: 100%;
        height: 100vh;
        position: relative;
        .top-box {
            width: 100%;
            height:53vh;
            z-index: 999;
            position: relative;
            .img-bg{
                width: 100%;
                height: 100%;
            
        }
    }
    }
    .message-text {
        color: #FFFFFF;
        font-size: 2.3331rem;
       
    }
    .English-text {
        position:absolute;
        left: 2.8125rem;
        top: 18.8125rem;
        color: #FFFFFF;
    
       
    }
    .logo {
        position:absolute;
        bottom: 1.6044rem;
        left: 2.4169rem;
        width: 3.875rem;
        height: 3.875rem;
    }
    .phone {
        position:absolute;
        bottom: 1.6044rem;
        right: 2.5419rem;
        height: 1.2919rem;
        width: 8.875rem;
    }
    .text-message {
        position:absolute;
        left: calc((100% - 17.5rem) / 2);
        width: 16.5625rem;
        height: 15.75rem;
        top: 5.1437rem;
    }
    
</style>