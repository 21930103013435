
<template>
  <div
    class="modal-bg"
    v-show="show"
    @mousemove="modalMove"
    @mouseup="cancelMove"
    @click.stop="stopMp"

  >
    <div class="modal-container">
      <div class="modal-header" @mousedown="setStartingPoint">{{ title }}</div>
      <div class="modal-main">
        <div class="ipts">
          <input type="text" placeholder="您的姓名" class="ipt1" v-model="form.name"/>
          <input type="text" placeholder="您的电话" class="ipt2" v-model="form.mobile"/>
        </div>
        <div class="msg">
          <textarea class="texts" placeholder="您想咨询的内容" v-model="form.consult" style='width:98%'></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <div class="submit" @click="submits">立即提交</div>
        <!-- <button round @click="hideModal">取消</button>
        <button type="primary" round @click="submit">确认</button>-->
      </div>
    </div>
  </div>
</template>

<script>
import { submit } from "../network/gobal";
export default {
  name: "modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      node: null,
      isCanMove: false,
      form:{
        name:'',
        mobile:'',
        consult:''
      }
    };
  },

  mounted() {
    this.node = document.querySelector(".modal-container");
  },
  methods: {
    submits() {

      const ruleName = /^(?:[\u4e00-\u9fa5]+)(?:·[\u4e00-\u9fa5]+)*$|^[a-zA-Z0-9]+\s?[\.·\-()a-zA-Z]*[a-zA-Z]+$/;
      const rulePhone = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/;

      if (!this.form.name) {
         this.message('请输入您的姓名','提醒')
      } else if (!ruleName.test(this.form.name)) {
        this.message('请输入正确的姓名','提醒')
      } else if (!this.form.mobile) {
        this.message('请输入您的电话号码','提醒')
      } else if (!rulePhone.test(this.form.mobile)) {
        this.message('请输入正确的电话号码','提醒')
      }

      else if (!this.form.consult) {
        this.message('请输入您想咨询的内容','提醒')
      } else if (this.form.consult.length>250) {
        this.message('咨询的内容最长不能超过250个字符','提醒')
      }

      else {
        
        submit(this.form).then(res => {
          if (res.code == 200) {
            this.message('快进商店工作人员将尽快与您取得联系。','提交成功')
            this.form = {
              name: "",
              mobile: "",
              consult: ""
            };
            this.$emit('submit')
          } else {
            this.message(res.msg,'提醒')
          }
        });
      }
    },
    stopMp() {
      // document.body.parentNode.style.overflow="scroll";//隐藏且禁用
     this.$emit("stopMp");
    },
    hideModal() {
      // document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.$emit("hideModal");
    },

    submit() {
      // document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.$emit("submit");
    },

    setStartingPoint(e) {
      this.x = e.clientX - this.node.offsetLeft;
      this.y = e.clientY - this.node.offsetTop;
      this.isCanMove = true;
    },

    modalMove(e) {
      if (this.isCanMove) {
        this.node.style.left = e.clientX - this.x + "px";
        this.node.style.top = e.clientY - this.y + "px";
      }
    },

    cancelMove() {
      this.isCanMove = false;
    },
    message(val,title=''){
      this.$alert(`<span style="font-size:16px">${val}</span>`, title, {
          confirmButtonText: '确定',
          showClose:false,
          customClass:'customClass',
          dangerouslyUseHTMLString: true,
          callback: action => {
            
          }
      });
    }
  }
};
</script>
<style scoped>
.submit {
  width: 16.25rem;
  height: 2.8125rem;
  background: #1810e7;
  border-radius: 3px;
  font-size: 1.25rem;
  font-family: opposm,sans-serif;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 2.8125rem;
  margin-top: 30px;
  cursor: pointer;
  letter-spacing: 2px;
}
textarea{
  white-space:pre-wrap;
 white-space:-moz-pre-wrap;
 white-space:-o-pre-wrap;
 word-wrap:break-word;
}
.submit:hover{
  background-color: #4234fa;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
input {
  border: 0px;
  padding-left: 10px;
}
::-webkit-input-placeholder {
   color: #CCCCCC;
   font-family: opposr,sans-serif;
   letter-spacing: 1px;
}
::-webkit-textarea-placeholder {
   color: #CCCCCC;
    font-family: opposr,sans-serif;
      letter-spacing: 1px;
}
textarea {
  border: 0px;
  background: #f2f2f2;
  border-radius: 3px;
  padding-top: 15px;
  padding-left: 10px;
}
.msg {
  width: 600px;

  margin: 20px auto 20px;
}
.texts {
  width: 100%;
  height: 218px;
  background: #f2f2f2;
  border-radius: 3px;
}
.ipts {
  width: 600px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.ipt1 {
  width: 247px;
  height: 45px;
  background: #f2f2f2;
}
.ipt2 {
  width: 290px;
  height: 45px;
  background: #f2f2f2;
}
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(175, 175, 175, 0.5);
  border-radius: 10px;
}
.modal-container {
  width: 700px;
  height: 490px;
  box-shadow: 0px 0px 10px 0px rgba(175, 175, 175, 0.5);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header {
  height: 62px;
  background: #1810e7;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  font-size: 23px;
  font-family: opposm,sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.modal-footer button {
  width: 100px;
}
.modal-main {
  width: 100%;
  height: 280px;
}
</style>
