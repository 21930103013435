<template>
  <div class="mDevplatform">
    <div class="banner">
      <div class="float-box">
        <div class="title">快进商店数据开放平台</div>
        <div class="desc">数字化赋能零售企业</div>
        <div class="desc">共同打通智能零售全链路发展</div>
      </div>
      <div class="btn-box">
        <div class="btn" @click="openDialog">立即加入</div>
      </div>
      <img :src="banner.preview" />
    </div>
    <div class="homebox">
      <div class="title">商务合作</div>
      <div class="desc">高效合作流程，助您快速开展合作</div>
      <div class="img-box">
        <img :src="img1" alt="商务合作" />
      </div>
       <div class="btn-box">
        <div class="btn" @click="openDialog">立即申请</div>
       </div>
    </div>
    
    <mGobalFooter />
  </div>
</template>

<script>
import mGobalFooter from "../../components/mobile/mGobalFooter";
export default {
  components: { mGobalFooter },
  data() {
    return {
      banner: {
        preview: require("../../assets/mobile/devplatform/banner.png"),
      },
      img1:require("../../assets/mobile/devplatform/img1.png"),
    };
  },
  methods: {
      openDialog() {
      this.$router.push("/mDialogConsult");
    },
  },
};
</script>

<style lang="scss" scoped>
.mDevplatform {
  margin-top: 55px;
  width: 100%;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 0.9375rem;
    font-weight: 400;
    color: #ffffff;
    width: 6.25rem;
    height: 1.9375rem;
    background: #1f10e0;
    border-radius: 3px;
    font-family: opposm;
  }
  .banner {
    width: 100%;
    position: relative;
    height: 26.0625rem;
    img {
      width: 100%;
      height: 26.0625rem;
    }
    .float-box {
      position: absolute;
      width: 15.1875rem;
      top: 2.0625rem;
      left: calc((100% - 15.1875rem) / 2);
      .title {
        text-align: center;
        margin-bottom: 0.6rem;
        font-size: 1.5rem;
        font-family: opposm;
        font-weight: 500;
        color: #3b3b72;
      }
      .desc {
        text-align: center;
        // margin-bottom: 0.5rem;
        font-size: 0.8125rem;
        font-family: opposr;
        font-weight: 400;
        color: #4e4d5e;
      }
    }
    .btn-box {
      position: absolute;
      bottom: 1.5625rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .homebox {
    width: 100%;
    height: 26.5625rem;
    .title {
      text-align: center;
      margin-top: 2.0625rem;
      font-size: 1.5rem;
      font-family: opposm;
      font-weight: 500;
      color: #3b3b72;
    }
    .desc {
      text-align: center;
      margin-top: 0.6rem;
      font-size: .8125rem;
      font-family: opposr;
      font-weight: 400;
      color: #4e4d5e;
    }
    .img-box {
        margin: 1.3125rem auto 0px;
        width: 20.125rem;
        height: 15.125rem;
        img {
            width: 20.125rem;
            height: 15.125rem;
        }
    }
    .btn-box {
        margin-top: 1.625rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }
  }
  
}
</style>